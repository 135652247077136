import React from "react"
import "./career-header.scss"
import Affix from "../affix"
import ActionComponent from "../../common/action-component/action-component"
import { graphql, useStaticQuery } from "gatsby"
import { merge } from "lodash"
import getLocaleEntities from "../../../helpers/localization-helper"

const query = graphql`
    fragment PageDataTranslations on StrapiGlobal {
        locale
        translations {
            phrase
            translated
        }
        localEntities {
            locale
            translations {
                phrase
                translated
            }
        }
    }
    query {
        strapiGlobal {
            ...PageDataTranslations
        }
    }
`
const CareerHeader = ({job, locale}) => {
  const servicePath = "strapiGlobal"
  const viewData = useStaticQuery(query)
  const localizedData = getLocaleEntities({viewData, servicePath, locale})

  const { translations } = localizedData
  const phrases = merge(...translations.map(item => {return {[item.phrase]: item.translated}}));
  const localePrefix = locale !== "en" ? `/${locale}` : ""

  return (
    <Affix offset={256}>
      <header className="career-header">
        <div className="container px-0">
          <div className="row">
            <div className="col-12 col-md-3">
              <ActionComponent type="anilink"
                               aniLinkOptions={{cover:true, direction:"right", top:"exit", duration:.4, bg:"#f4f4f0" }}
                               src={`${localePrefix}/about#career`} className="px-0" startIcon="arrow" text={phrases['back']} theme="dark" />
            </div>
            <div className="row align-items-end">
              <div className="col-12 col-md-9">
                <h2 className="career-tags-mini">
                  <span>{job.EmploymentStatus}</span>
                  <span className="circle">•</span>
                  <span>{job.JobLocation}</span>
                  <span className="circle">•</span>
                  <span>{job.Experience}</span>
                </h2>
                <h1 className="career-heading">{job.heading}</h1>
              </div>
              <div className="col-12 col-md-3 mt-4 mt-md-0 text-md-right">
                <ActionComponent target="_blank" type="bordered" endIcon="arrow" text="APPLY" src={job.JobUrl} theme="dark" />
              </div>
            </div>
          </div>
          <div className="career-tags flex justify-content-between">

            <div className="career-tag">
              <div className="tag-id">{phrases['published_on']}</div>
              <div className="tag-value">{job.PublishDate}</div>
            </div>
            <div className="career-tag">
                <div className="tag-id">{phrases['employment_status']}</div>
              <div className="tag-value">{job.EmploymentStatus}</div>
            </div>
            <div className="career-tag">
                <div className="tag-id">{phrases['job_location']}</div>
              <div className="tag-value">{job.JobLocation}</div>
            </div>
            <div className="career-tag">
                <div className="tag-id">{phrases['experience']}</div>
              <div className="tag-value">{job.Experience}</div>
            </div>
            <div className="career-tag">
                <div className="tag-id">{phrases['vacancy']}</div>
              <div className="tag-value">{job.Vacancy}</div>
            </div>

          </div>
        </div>
      </header>
    </Affix>
  )
}

export default CareerHeader
