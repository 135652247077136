import React from "react"
import "./career-op-item.scss"
import RichText from "../../shared/rich-text/rich-text"

const CareerOpItem = ({data}) => {
  return (
    <div className="career-op-content">
      <div className="container p-0">
        <div className="row">
          <div className="col-12 col-md-9">
            <RichText data={data} />
          </div>
        </div>

      </div>
    </div>
  )
}

export default CareerOpItem
