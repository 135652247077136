import React from "react"
import PropTypes from "prop-types"
import Seo from "../shared/seo"
import GridBorder from "../common/grid-border/grid-border"

const PopupLayout = ({ children, seo }) => {

  return <>
    <Seo seo={seo} />
    <GridBorder />
    {children}
  </>
}

PopupLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default PopupLayout
