import * as React from "react"

import DefaultLayout from "../components/layout/default-layout"

const NotFoundPage = () => (
  <DefaultLayout type="dark" locale={'en'}>
    <div className="container">
      <h1 className="title">404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </DefaultLayout>
)

export default NotFoundPage
