import React, { Component } from 'react';
import './affix.scss';

class Affix extends Component {

  static propTypes = {
    offset: Number,
  };

  static defaultProps = {
    offset: 0,
  };

  constructor() {
    super();
    this.state = {
      affix: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    document.querySelector('body').classList.remove('mt-96');
  }

  handleScroll = () => {
    const affix = this.state.affix;
    const offset = this.props.offset;
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

    if (!affix && scrollTop >= offset) {
      this.setState({
        affix: true,
      });
      document.querySelector('body').classList.add('mt-96');
    }

    if (affix && scrollTop < offset) {
      this.setState({
        affix: false,
      });
      document.querySelector('body').classList.remove('mt-96');
    }
  };

  render() {
    const affix = this.state.affix ? 'affix' : '';
    const { className, ...props } = this.props;

    return (
      <div {...props} className={`${className || ''} ${affix}`}>
        {this.props.children}
      </div>
    );
  }
}

export default Affix;
